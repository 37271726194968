import styles from './index.module.scss'
import { useTranslation } from 'next-i18next'
import Image from 'next/image'
import atavar1 from '@images/home/atava@2x.png'
import atavar2 from '@images/home/atavar2@2x.png'
import maohaoPic from '@images/home/maohao.png'
import bdfree from '@images/bdfree.png'
import mbdfree from '@images/mbdfree.png'
import buriedSdk from '@/utils/zz-buried/index'
import { useWidth } from '@/utils/resize'
type Props = {}
const Index = (props: Props) => {
  const { t, i18n } = useTranslation('home')
  const { width, display } = useWidth()
  return (
    <div className={[styles['free-box'], 'relative'].join(' ')}>
      <div
        className={`defeaultContainer animate-box ${styles['free-center']} ${styles['free-content']}`}
      >
        <h1 className={`${styles['free-box-title']}  ant`}>
          {/* Focus on TikTok E-commerce Data Analysis */}
          {t('Focus on TikTok Analytics')}
        </h1>
        <a
          href={width > 1024 ? process.env.portalUrl + '/home' : process.env.mobileUrl}
          className={`${styles.get} ant`}
          onClick={() => {
            buriedSdk.buried({
              event: `Shoplus官网_${width > 1024 ? 'Web' : 'H5'}`,
              scene: `底部引导_Start Free Trial`,
              URL: process.env.publicApiDomain
            })
          }}
        >
          {t('Start Free Trial')}
        </a>
      </div>
      <div className={`${styles['free-box-bg']} `}>
        <div
          className={styles.pcBg}
          style={{ position: 'relative', width: '100%', height: '324px' }}
        >
          <Image
            src={bdfree}
            layout="fill"
            objectFit="cover"
            alt="TikTok E-commerce Data Analysis"
          />
        </div>
        <div className={styles.mBg} style={{ position: 'relative', width: '100%', height: '100%' }}>
          <Image
            src={mbdfree}
            layout="fill"
            objectFit="cover"
            alt="TikTok E-commerce Data Analysis"
          />
        </div>
      </div>
    </div>
  )
}
export default Index
